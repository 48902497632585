<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>

    <c-loader-line v-show="loadingStatus" />
    <vue-confirm-dialog
      class="confirm-dialog"
      :class="`${ $store.state.variantPopupConfirm }-variant-dialog`"
    ></vue-confirm-dialog>
    <notifications group="hint" style="margin-top: 30px" /> 
    <notifications group="confirm" width="100%" position="top center">
      <template slot="body" slot-scope="{ item, close }">
        <div class="custom__notification" :class="item.type">
          <div class="d-flex custom__notification__content">
            <img
              v-if="item.type == 'success'"
              src="/assets/images/tick.png"
              alt=""
              :class="`custom__notification__content__img__${item.type}`"
            />
            <img
              v-if="item.type == 'error'"
              src="/assets/images/error.png"
              alt=""
              :class="`custom__notification__content__img__${item.type}`"
            />
            <img
              v-if="item.type == 'warn'"
              src="/assets/images/warn.png"
              alt=""
              :class="`custom__notification__content__img__${item.type}`"
            />
            <div class="custom__notification__textContainer">
              <p class="custom__notification__text txtContainer">
                <span
                  class="custom__notification__text name cursor-pointer"
                  v-if="item.data.name && !item.data.hasAction"
                  v-html="item.data.name"
                ></span>
                <span
                  class="custom__notification__text name cursor-pointer"
                  v-if="item.data.name && item.data.hasAction"
                  v-html="item.data.name ? item.data.name.padStart(item.data.name.length+1,'\'')
                            .padEnd(item.data.name.length+2, '\'').padEnd(item.data.name.length+3, ' ') : ''"
                  @click="item.data.action"
                ></span>
                <span
                  class="custom__notification__text status"
                  v-if="item.data.status"
                  v-html="item.data.status"
                ></span>
              </p>
            </div>
          </div>
          <p
            v-if="item.data.actiontext"
            class="btn custom__notification__close"
            @click="close"
          >
            <span class="btn custom__notification__close__text">{{
              item.data.actiontext
            }}</span>
            <img
              class="btn custom__notification__close__img"
              src="/assets/images/close.png"
              alt=""
            />
          </p>
        </div>
      </template>
    </notifications>
    <c-back-to-top />
  </div>
</template>

<!--:class="{'dialog-new-design': minimizedInfo}"-->

<script>
export default {
  components: {
    "c-loader-line": () => import("@/lib/framework/waitLoader"),
    "c-back-to-top": () => import("@/lib/framework/actions/scrollTop"),
  },
  computed: {
    loadingStatus() {
      return this.getLoadingStatus;
    }
  },
  watch: {
    ['$store.getters.getLockBodyScroll'](value) {
      if (value) {
        document.body.classList.add("lock-scroll");
      } else {
        document.body.classList.remove("lock-scroll");
      }
    }
  },
  mounted() {
    console.log("App.mounted() ...");

    const errorText = "Sorry, something went wrong. Please try again later, if the error persists please contact support.";

    if (!window.LINK_CONFIG) {
      this.$notify({
        group: "hint",
        title: "LINK_CONFIG not found",
        errorText,
        type: "error"
      });
    }

    if (!window.H_CONFIG) {
      this.$notify({
        group: "hint",
        title: "H_CONFIG not found",
        errorText,
        type: "error"
      });
    }

    if (!window.FB_CONFIG) {
      this.$notify({
        group: "hint",
        title: "FB_CONFIG not found",
        errorText,
        type: "error"
      });
    }

    if (this.$cookies.get("fbToken"))
      this.loadCurrentUser();

    console.log("App.mounted()");
  }
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500;700;900&display=swap");
* {
  box-sizing: border-box;
}
body {
  padding: 0;
  margin: 0;
  background: $colorBgGrey;
  font-family: $fontFamilyRedHatDisplay;
  min-width: $layoutWidthSmallest;

  &.lock-scroll {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
}

.slide-fade-enter-active {
  transition: all 0.1s ease;
}

.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  width: 100%;
  display: block;
  box-sizing: border-box;
  border: none;
  outline: none;
}

input {
  -moz-appearance: none;
  -webkit-appearance: none;
  width: 100%;
  display: block;
  box-sizing: border-box;
  border: none;
  outline: none;
}
*:focus {
  outline: none;
}

div {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}
.mx-datepicker {
  &-popup {
    z-index: 99999 !important;
  }
  .mx-input {
    height: 52px !important;
    background-color: $colorBgTextWhite;
    border: 1px solid $colorBgTextNormalGrey;
    color: $colorBgTextDarkGrey;
    box-shadow: none;
    font-size: $fontSizeTextLg;
    font-family: $fontFamilyRedHatDisplay;
    padding-right: 28px;
    &:focus {
      outline: none;
    }
  }
  .mx-icon-calendar {
    color: $colorBgTextFillGrey;
    margin-right: 2.5px;
    svg {
      width: 21px;
      height: 21px;
    }
  }
}

.v-select {
  height: 52px !important;
  width: 100% !important;
  border-radius: 4px !important;

  .vs__actions {
    padding: 0 15px 0 3px;
  }

  .vs__dropdown-toggle {
    height: 100%;
    border-radius: 4px !important;
    padding: 0 !important;
    border-color: $colorBgTextNormalGrey;
  }

  .vs__selected-options {
    height: 100%;
    padding-left: 7px;
    input {
      margin: 0;
      &::placeholder {
        color: $colorBgTextNormalGrey;
      }
    }

    .vs__selected {
      font-size: $fontSizeTextLg;
      height: 100%;
      margin: 0;
      padding: 10px;
      font-weight: $fontWeightRegular;
    }
  }
  @media (max-width: $layoutWidthMobile) {
    height: 40px !important;
  }
}
svg {
  outline: none;
}
.tooltip {
  .tooltip-inner {
    max-width: 400px;
    word-break: break-word;
  }
}
</style>
