export class EventKey {
  constructor(id, templateId = null, occurrenceIndex = 0) {
    this.id = id;
    this.templateId = templateId;
    this.occurrenceIndex = occurrenceIndex ?? 0;
  }
}

export const Enums =  Object.freeze({

  UserRoles: {
    ADMIN: "admin",
    USER: "user"
  },

  Status: {
    DRAFT: 0,
    PUBLISHED: 1,
    COMPLETED: 2,
    CANCELED: 3
  },

  EventModalTabs: {
    DETAILS: 0,
    ATTENDEES: 1,
    QNA: 2
  },

  RecurrenceIntervals: { 
    NO_REPEAT: { name: "Does not repeat", value: "0" },
    DAILY: { name: "Daily", value: "1" },
    WEEKLY: { name: "Weekly", value: "2" },
    MONTHLY: { name: "Monthly", value: "3" },
    WEEKDAYS: { name: "Every weekday (Mon-Fri)", value: "4" },

    getItems: function() {
      let intervals = [...Object.values(this)];
      // All properties except the current function.
      intervals = intervals.filter((property) => property.name !== "getItems");

      return intervals;
    }
  },

  RecurrenceUpdate: {
    ONE: "0",
    FOLLOWING: "1",
    All: "2"
  },

  ModalType: {
    NO_MODAL: null,
    SIGN_IN: "signIn",
    ADD_GROUP: "addGroup",
    INVITE: "invite",
    EVENT: "event",
    POST: "post",
    GROUP: "group",
    RSVP: "rsvp",
    RSVP_QR_CODE: "qrCode"
  },

  ModalResults: {
    NONE: 0,
    CONFIRM: 1,
    SAVE: 2,
    CANCEL: 3
  },

  PopupType: {
    // Event
    EVENT_CHANGE: { variant: "info", value: "eventChange" },
    EVENT_REMINDER: { variant: "info", value: "eventReminder" },
    CANCEL_RECURRING_EVENT: { variant: "info", value: "cancel" },
    EDIT_RECURRING_EVENT: { variant: "info", value: "edit" },
    PUBLISH_EVENT: { variant: "info", value: "publishEvent" },
    CANCEL_EVENT: { variant: "info", value: "cancelEvent" },
    DELETE_EVENT: { variant: "danger", value: "event" },

    // Post
    PUBLISH_POST: { variant: "info", value: "publishPost" },
    CANCEL_POST: { variant: "info", value: "cancelPost" },
    DELETE_POST: { variant: "danger", value: "post" },

    // Member
    REMOVE_MEMBER: { variant: "danger", value: "removeMember" },

    // Group
    DELETE_GROUP: { variant: "danger", value: "group" },
    DISABLE_LINK: { variant: "info", value: "disableLink" }, 
    GENERATE_LINK: { variant: "info", value: "generateLink" },

    // Common
    FILE_IMPORT: { variant: "info", value: "fileImport" },
    RESULTS_IMPORT: { variant: "info", value: "resultsImport" },
    DISCARD_CHANGES: { variant: "danger", value: "discardChanges" },
    DELETE_ACCOUNT: { variant: "danger", value: "deleteAccount" },

    // Zoom 
    DISCONNECT_ZOOM: { variant: "danger", value: "disconnectZoom" },
    DELETE_ZOOM: { variant: "danger", value: "deleteZoom" },
  },

  GroupTabs: {
    EVENTS: "Events",
    POSTS: "Posts",
    MEMBERS: "Members"
  },

  Layouts: {
    SMALLEST: 320,
    MOBILE: 480,
    COMPACT_S: 576,
    COMPACT: 767,
    COMPACT_L: 992,
    NORMAL_S: 1024,
    NORMAL: 1200,
    WIDE: 1440
  }
})


export const RegExp = Object.freeze({
  WEBSITE: /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
  PHONE: /^[- +()]*[0-9][- +()0-9]*$/,
  DIGIT_NUMBERS: /^\d+(e?\d*)$/,
  PASSWORD: /^\S*$/,
  BASE64_APPLICATION: /^data:application\/[A-Za-z0-9_.~-]+;base64,/,
  BASE64_IMAGE: /^data:image\/[a-z]+;base64,/,
  USERAGENT_IOS_WEB: /(Version\/\d+.*\/\d+.0.0.0 Mobile|; ?wv|(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari))/i,
  USERAGENT_IOS: /iphone|ipod|ipad/,
  USERAGENT_SAFARI: /safari/
})
